<template>
    <div>
        <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
                <div class="row">
                    <div class="col-md-6">
                        <strong>Block Users</strong>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <b-row class="">
                    <b-col>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group
                                        >
                                        <template v-slot:label>
                                        Search
                                        </template>
                                        <b-form-input
                                            class="form-control"
                                            id="search"
                                            v-model="search.search"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-2">
                                        <label>&nbsp;</label>
                                        <b-button class="btn-sm" style='position:absolute;bottom:16px' type="submit" variant="primary">Search</b-button>
                                    </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-row>
                <b-overlay :show='loading'>
                    <div class="overflow-auto">
                        <b-table thead-class="bg-light text-dark" emptyText="Data Not Found" small show-empty bordered hover :items="itemList" :fields="fields">
                            <template v-slot:cell(index)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(name)="data">
                                <router-link :to='`/users/profile?id=` + data.item.id' >
                                    {{ data.item.name }}
                                </router-link>
                            </template>
                            <template v-slot:cell(picture)="data">
                                <div v-if="data.item.picture">
                                    <img width="40px" :src="baseUrl + data.item.picture">
                                </div>
                            </template>
                            <template v-slot:cell(status)="data">
                                <span class="badge badge-success" v-if="data.item.status == 1">Active</span>
                                <span class="badge badge-danger" v-else>Block</span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <b-button v-if="data.item.status == 2" title="Active" class="btn btn-success ml-2 btn-sm" @click="changeStatus(data.item)"> <i class="ri-check-line"></i></b-button>
                                <b-button v-else title="Block" class="ml-2 btn btn-danger btn-sm" @click="changeStatus(data.item)"><i class="ri-close-line"></i></b-button>
                            </template>
                        </b-table>
                    </div>
                </b-overlay>
                <b-pagination
                class="text-right"
                v-model="pagination.currentPage"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                @input="searchData"
                ></b-pagination>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <b-modal id="modal-1"
        size="lg"
        header-bg-variant="primary"
        header-text-variant="light"
        title="Notification" hide-footer>
        <div>
            <Notification :id='editId'/>
      </div>
      </b-modal>
    </div>
    </template>
    <script>
    import RestApi, { baseUrl } from '../../config/api_config'
    import { ValidationObserver } from 'vee-validate'
    import iziToast from 'izitoast';
    import Notification from './Notification'
    
    export default {
        components: {
            ValidationObserver,
            Notification
        },
        created () {
            this.loadData ()
        },
        data() {
          return {
            baseUrl: baseUrl,
            search: {
                search: '',
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            editId: ''
          }
        },
        computed: {
            itemList () {
                return this.$store.state.list
            },
            fields () {
                const labels = [
                    { label: 'User ID', class: 'text-center' },
                    { label: 'Image', class: 'text-left' },
                    { label: 'Name', class: 'text-left' },
                    { label: 'Email', class: 'text-left' },
                    { label: 'Mobile', class: 'text-left' },
                    { label: 'Point', class: 'text-center' },
                    { label: 'Acc. IP', class: 'text-center' },
                    { label: 'Status', class: 'text-center' },
                    { label: 'Action', class: 'text-center' }
                ]
    
                let keys = []
                keys = [
                { key: 'id' },
                { key: 'picture' },
                { key: 'name' },
                { key: 'email' },
                { key: 'mobile' },
                { key: 'point' },
                { key: 'account_ip' },
                { key: 'status' },
                { key: 'action' }
                ]
                return labels.map((item, index) => {
                    return Object.assign(item, keys[index])
                })
            },
            loading () {
              return this.$store.state.static.loading
            },
            listReload () {
              return this.$store.state.static.listReload
            }
        },
        watch: {
          listReload: function (newVal) {
            if (newVal) {
                this.loadData()
            }
          }
        },
        methods: {
            edit (item) {
                this.editId = item.id
            },
            changeStatus (item) {
                this.$swal({
                    title: 'Are you sure to change status ?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.toggleStatus(item)
                    }
                })
            },
            toggleStatus (item) {
                RestApi.deleteData(baseUrl, `api/user-signup/user-status/${item.id}`).then(response => {
                    if (response.success) {
                        this.$store.dispatch('mutedLoad', { listReload: true })
                        iziToast.success({
                            title: 'Success',
                            message: response.message
                        })
                    } else {
                        iziToast.error({
                            title: 'Success',
                            message: response.message
                        })
                    }
                })
            },
            searchData () {
                this.loadData()
            },
            loadData () {
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, status: 2 })
                this.$store.dispatch('mutedLoad', { loading: true})
                RestApi.getData(baseUrl, 'api/user-signup/list', params).then(response => {
                    if (response.success) {
                        this.$store.dispatch('setList', response.data.data)
                        this.paginationData(response.data)
                    }
                    this.$store.dispatch('mutedLoad', { loading: false })
                })
            },
            paginationData (data) {
                this.pagination.perPage = parseInt(data.per_page)
                this.pagination.currentPage = parseInt(data.current_page)
                this.pagination.total = parseInt(data.total)
            }
        },
        filters: {
            subStr: function(string) {
                return string.substring(0, 80) + '...';
            }
        }
    }
    </script>